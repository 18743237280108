import React, { useState, useEffect, useContext } from "react";
import Hader from "../Component/Hader";
import { Form, FormGroup, Label, Input, Button } from "reactstrap"; // Import Reactstrap components
import { MyContext } from "../MyContext";
import { MdOutlineNoteAlt } from "react-icons/md";
import { Helmet } from "react-helmet";
import Footer from "../Component/Footer";
import moment from "moment-timezone";
import "./../assets/css/checkout.css";
import { getUsers } from "../functions/Auth/Users";
import { MdOutlineStars } from "react-icons/md";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import {
  createUserShippingAddress,
  getUserShippingAddress,
  updateUserShippingAddress,
  updateDefaultAddress,
  updateBillingValueSA,
  updatebillingIdSA,
  getDefaultShippingAddressByUser,
  getAllShippingAddressofUser,
} from "../functions/Auth/UserShippings";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  createUserBillingAddress,
  updateDefaultBillingAddress,
  removeUserBillingAddress,
  getDefaultBillingAddressByUser,
} from "../functions/Auth/userBillingAddress";
import {
  listDeliveryOption,
  getDeliveryOption,
} from "../functions/DeliveryOptions/DeliveryOption";
import {
  createOrderInOneGo,
  createOrderInOneGoHeartLand,
} from "../functions/Orders/Order";
// import Icon1 from "./../assets/img/icon_01.png";
import maestro from "./../assets/img/maestro.svg";
import mastercard from "./../assets/img/mastercard.svg";
import visa from "./../assets/img/visa.svg";
import amex from "./../assets/img/amex.svg";
import paypal from "./../assets/img/payPal.svg";
import offline from "./../assets/img/offline.svg";
import { CiShoppingCart, CiShoppingTag, CiLock } from "react-icons/ci";
import {
  listState,
  listCountry,
  getCountry,
  getState,
} from "../functions/Location/Location";
import {
  getSetupByName,
  sendEmailOnOrderPlaced,
} from "../functions/Notifications/EmailNotifications";
import { CiGift } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { FaGoogle, FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import { IoLockClosedSharp } from "react-icons/io5";
import { SlPaypal } from "react-icons/sl";
import { IoBagCheckOutline } from "react-icons/io5";
import Cart from "../Component/Cart";
import { getUserCartByUserId } from "../functions/Auth/UserCart";
import { listCompanynDetails } from "../functions/CompanyDetails/CompanyDetails";
import { getProductVariantsInfoInCart } from "../functions/ProductDetails/ProductDetails";
import { listLocationDetails } from "../functions/Location/companyLocation";
import { CheckBox, Login, LoginOutlined } from "@mui/icons-material";
import { Puff, TailSpin } from "react-loader-spinner";
import dayjs from "dayjs";
import MobileHeader from "../Component/MobileHeader";
import {
  checkPromoExists,
  getProductPoints,
  listPromo,
} from "../functions/Loyalty/LoyaltyPointMaster";
import { Box, LinearProgress, Typography } from "@mui/material";
import { getPointTransactionByUserId } from "../functions/Loyalty/PointTransaction";
import PromoCarousel from "../Component/PromoCarousel";
import { getPointsConversation } from "../functions/Loyalty/PointConversation";
import { listTipMaster } from "../functions/TipMaster/TipMaster";
import {
  addCreditCard,
  CreateCreditCardOnCheckout,
  getCreditCardByuserId,
} from "../functions/Auth/CreditCard";
import { listContent } from "../functions/CMS/cmsContent";

const initialState = {
  userId: localStorage.getItem("NicksCoUser"),
  firstName: "",
  lastName: "",
  contactNo: "",
  companyName: "",
  city: "",
  stateId: "",
  countryId: "",
  addressLine1: "",
  addressLine2: "",
  zipCode: "",
  isBillingSame: false,
  billingId: null,
  IsActive: true,
};

const initialStateBA = {
  userIdBA: localStorage.getItem("NicksCoUser"),
  firstNameBA: "",
  lastNameBA: "",
  contactNoBA: "",
  companyNameBA: "",
  cityBA: "",
  stateIdBA: "",
  countryIdBA: "65b9e7c8a988700c5918f830",
  addressLine1BA: "",
  addressLine2BA: "",
  zipCodeBA: "",
  isBillingSameBA: false,
  shippingId: null,
  IsActiveBA: true,
};

const initialLoginState = {
  email: "",
  password: "",
};

const Checkout = () => {
  const {
    setcartDetails,
    setSubTotal,
    setCartCount,
    cartCount,
    count,
    setCount,
    cartDetails,
    subTotal,
    // setShippingCharge,
    prodVariantsData,
    setProdVariantsData,
    // shippingCharge,
  } = useContext(MyContext);

  useEffect(() => {
    getUsers(userId)
      .then((res1) => {
        console.log("user", res1.data);
        initialStateBA.firstNameBA = res1.data.firstName;
        initialStateBA.lastNameBA = res1.data.lastName;
        initialStateBA.contactNoBA = res1.data.contactNo;
      })
      .catch((err) => {
        console.log("error", err);
      });

    console.log("thisiscart", cartDetails);

  }, []);

  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisplayError, setIsDIsplayError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isDisplayErrorBA, setIsDIsplayErrorBA] = useState(false);
  const [formErrorsBA, setFormErrorsBA] = useState({});
  const [isSubmitBA, setIsSubmitBA] = useState(false);

  const [defaultAdd, setDefaultAdd] = useState(false);
  const [checkedAddresses, setCheckedAddresses] = useState([]);
  const [ShippingAddressData, setShippingAddressData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [allShippingAddress, setAllShippingAddress] = useState([]);
  const [deliveryOptionsData, setDeliveryOptionsData] = useState([]);
  const [shippingCharge, setShippingCharge] = useState(0);

  const [dateOption, setDateOption] = useState(false);
  const [dateValidate, setDateValidate] = useState(false);
  const [minDate, setMinDate] = useState(dayjs(new Date())); // Initialize minDate with today's date
  const [maxDate, setMaxDate] = useState(dayjs(new Date()));
  const [isSpinner, setIsSpinner] = useState(false);
  const [loyaltypoints, setLoyaltypoints] = useState();
  const [isLoyaltypoints, setIsLoyaltypoints] = useState(false);
  const [points, setPoints] = useState(0);
  const [totalAmount, setTotalAmount] = useState(subTotal);
  const [discount, setDiscount] = useState(0);
  const [creditedLoyaltyPoints, setCreditedLoyaltyPoints] = useState(0);
  const [productPoints, setProductPoints] = useState(0);
  const [productDiscount, setProductDiscount] = useState(0);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [promoLoyaltypoints, setPromoLoyaltypoints] = useState(0);
  const [promoList, setPromoList] = useState([]);
  const [promoCode, setPromocode] = useState("");
  const [isPromo, setPromo] = useState(false);
  const [promoDetails, setPromoDetails] = useState();
  const [notValidPromo, setNotValidPromo] = useState(false);
  const [pointsPerDollar, setPointsPerDollar] = useState();

  const [tipList, setTipList] = useState([]);

  const [addTip, setAddTip] = useState(0);
  const [isTip, setIsTip] = useState(false);
  const [tipPoint, setTipPoint] = useState(0);

  const [expirationdate, setExpirationdate] = useState("");
  const [shower, setShower] = useState(false);



  // credit card
  const [allCreditCards, setAllCreditCards] = useState([]);
  const [selectedCreditCard, setSelectedCreditCard] = useState();
  const [toggleCreditCardForm, setToggleCreditCardForm] = useState(false);
  const [cardValues, setCardValues] = useState({
    cardNumber: "",
    cardHolderName: "",
    expiryDate: "",
    cvv: "",
  });

  useEffect(() => {
    setDateOption(true);
  }, []);

  const [deliveryData, setDeliveryData] = useState({
    deliveryTypeId: null,
    deliveryAddress: "66ec9b4738bcbc34d37a6d1f",
    deliveryTime: null,
  });

  const handleDeliveryAddress = (e) => {
    setDeliveryData({ ...deliveryData, deliveryAddress: e.target.value });
  };
  const UserID = localStorage.getItem("NicksCoUser");
  const [isUserID, setIsUserID] = useState(false);

  useEffect(() => {
    if (UserID) {
      setIsUserID(true);
    }
  }, [UserID]);
  const {
    userId,
    firstName,
    lastName,
    contactNo,
    companyName,
    city,
    stateId,
    countryId,
    addressLine1,
    addressLine2,
    zipCode,
    isBillingSame,
    IsActive,
  } = values;
  const [valuesBA, setValuesBA] = useState(initialStateBA);
  const {
    userIdBA,
    firstNameBA,
    lastNameBA,
    contactNoBA,
    companyNameBA,
    cityBA,
    stateIdBA,
    countryIdBA,
    addressLine1BA,
    addressLine2BA,
    zipCodeBA,
    isBillingSameBA,
    shippingId,
    IsActiveBA,
  } = valuesBA;

  useEffect(() => {
    loadCountries();
    loadStates();
    loadUserCart();
    loadCompanyDetails();
    loadDefaultShippingAdd();
    loadAllShippingAddress();
    loadDeliveryOptions();
    loadCreditCards();
  }, []);

  const loadCountries = () => {
    listCountry().then((res) => {
      setCountries(res.data);
    });
  };

  const loadStates = () => {
    listState().then((res) => {
      setStates(res.data);
    });
  };

  const loadAllShippingAddress = () => {
    getAllShippingAddressofUser(UserID).then((res) => {
      // console.log("all ship add", res.data);
      setAllShippingAddress(res.data);
    });
  };

  const loadCreditCards = () => {
    getCreditCardByuserId(UserID).then((res) => {
      // console.log("all credit data ", res.data);
      setAllCreditCards(res.data);
    });
  };

  const handleSaveCreditCard = (e) => {
    e.preventDefault();
    setFormErrors({});
    let errors = validateCard(cardValues);
    setFormErrors(errors);
    // setIsSubmit(true);
    setIsCardError(true);
    if (Object.keys(errors).length === 0) {
      CreateCreditCardOnCheckout({ ...cardValues, userId: UserID }).then(
        (res) => {
          // console.log("credit card data", res.data);
          if (res.data.isOk) {
            //  toast.success("Card added successfully!");
            setSelectedCreditCard(res.data.data._id);
            setIsCardError(false);
            setToggleCreditCardForm(false);
            setCardValues({
              cardNumber: "",
              cardHolderName: "",
              expiryDate: "",
              cvv: "",
            });
            loadCreditCards();
          }
        }
      );
    }
  };

  const [errCardN, setErrCardN] = useState(false);
  const [errCardName, setErrCardName] = useState(false);
  const [errCVV, setErrCVV] = useState(false);
  const [errExp, setErrExp] = useState(false);
  const [isCardError, setIsCardError] = useState(false);

  const validateCard = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const cvvregex = /^[0-9]{3, 4}$/;
    const visaregex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const mastercardregex =
      /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/;
    const cardreqgex = /^(?:d[ -]*?){13,16}$/;

    if (values.cardNumber === "") {
      errors.cardNumber = "Card Number is required!";
      setErrCardN(true);
    }
    if (values.cardNumber !== "") {
      setErrCardN(false);
    }

    if (values.cardHolderName === "") {
      errors.cardHolderName = "Card Holder Name is required!";
      setErrCardName(true);
    }
    if (values.cardHolderName !== "") {
      setErrCardName(false);
    }

    if (values.cvv === "") {
      errors.cvv = "cvv is required!";
      setErrCVV(true);
    }
    if (values.cvv !== "") {
      setErrCVV(false);
    }

    if (values.expiryDate === "") {
      errors.expiryDate = "Expiry Date is required!";
      setErrExp(true);
    }
    if (values.expiryDate !== "") {
      setErrExp(false);
    }

    return errors;
  };

  const validClassCardNumber =
    errCardN && isCardError ? "form-control is-invalid" : "form-control";
  const validClassCardName =
    errCardName && isCardError ? "form-control is-invalid" : "form-control";
  const validClassCVV =
    errCVV && isCardError ? "form-control is-invalid" : "form-control";
  const validClassExpDate =
    errExp && isCardError ? "form-control is-invalid" : "form-control";

  const [loginValues, setLoginValues] = useState(initialLoginState);
  const { email, password } = loginValues;

  const handleChangeLoginValues = (e) => {
    setLoginValues({ ...loginValues, [e.target.name]: e.target.value });
  };

  const [errEmail, seterrEmail] = useState(false);
  const [errPassword, setErrPassword] = useState(false);

  const validateLogin = (loginValues) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (loginValues.email === "") {
      errors.email = "Email is required!";
      seterrEmail(true);
    }
    // else if (!regex.test(loginValues.email)) {
    //   errors.email = "Invalid Email address!";
    //   seterrEmail(true);
    // }
    else {
      seterrEmail(false);
    }
    if (loginValues.password === "") {
      errors.password = "Password is required!";
      // console.log("password req");
      setErrPassword(true);
    }
    if (loginValues.password !== "") {
      setErrPassword(false);
    }
    return errors;
  };
  const validClassEmail =
    errEmail && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassPassword =
    errPassword && isSubmit ? "form-control is-invalid" : "form-control";

  const handleLogin = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    let erros = validateLogin(loginValues);
    setFormErrors(erros);
    // console.log("login values", loginValues, erros);
    if (Object.keys(erros).length === 0) {
      axios
        .post(`${process.env.REACT_APP_API_URL_COFFEE}/api/login`, {
          values: loginValues,
          guestCartData: localStorage.getItem("guestCartData")
            ? JSON.parse(localStorage.getItem("guestCartData"))
            : [],
        })
        .then((res) => {
          if (res.data.isOk) {
            const Res = res.data;
            localStorage.setItem("NicksCoUser", Res.data._id);
            //  toast.success("You have successfully logged in");
            setIsUserID(true);
            window.location.reload("/checkout");
            if (res.data.guestCartData) {
              localStorage.removeItem("guestCartData");
            }
          } else {
            toast.error("Authentication failed!");
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Authentication failed!");
        });
    }
  };
  const loadDeliveryOptions = () => {
    listDeliveryOption().then((res) => {
      // console.log("all delivery add", res.data);
      setDeliveryOptionsData(res.data);
      res.data.map((d) => {
        if (d.DeliveryCharge === 20) {
          // console.log("hh");
          setIsDelivery(d._id);
          setShippingCharge(d.DeliveryCharge);
        }
      });
    });
  };
  const [defualtShipId, setDefualtShipId] = useState("");

  const [VFN, setVFN] = useState("");
  const [VLN, setVLN] = useState("");
  const [VCN, setVCN] = useState("");
  const [VC, setVC] = useState("");
  const [Vcity, setVcity] = useState("");
  const [Vz, setVZ] = useState("");
  const [vA1, setVA1] = useState("");
  const [vA2, setVA2] = useState("");

  const loadDefaultShippingAdd = () => {
    getDefaultShippingAddressByUser(UserID).then((res) => {


      // console.log("res in default shipping", res.data);
      if (res.data.isOk) {
        const resp = res.data.data;
        setDefualtShipId(resp._id);
        setChooseDefualtShippAdd(resp._id);
        setVFN(resp.firstName);
        setVLN(resp.lastName);
        setVCN(resp.companyName);
        setVC(resp.contactNo);
        setVcity(resp.city);
        setVZ(resp.zipCode);
        setVA1(resp.addressLine1);
        setVA2(resp.addressLine2);
        getCountry(resp.countryId).then((countryRes) => {
          if (countryRes.data) {
            setCountryName(countryRes.data.CountryName);
          }
        });
        getState(resp.stateId).then((StateRes) => {
          // console.log("ddqq", StateRes);
          if (StateRes.data) {
            setStateName(StateRes.data.StateName);
          }
        });
        setIsContinue(false);
      }
    });
  };

  const loadCompanyDetails = () => {
    listLocationDetails().then((res) => {
      const r = res.data.map((c) => {
        return {
          CompanyName: c.CompanyName,
          Address: c.Address,
        };
      });
      setCompanyData(res.data);
    });
  };

  const [shippingWeight, setIsShippingWeight] = useState(false);
  const [totalCartWeight, setTotalCartWeight] = useState(0);

  const [isasub, setIsasub] = useState(false);

  const loadUserCart = async () => {
    try {
      if (UserID) {
        const res = await getUserCartByUserId(UserID);
        console.log("cart data", res.data);
        const resp = res.data.data;
        if (resp[0]?.subsId !== null) {
          setIsasub(true);
        };
        setcartDetails(resp);
        setCartCount(resp.length);
        setSubTotal(res.data.subTotal);
        let isShippingWeight = false;
        let totalWeight = 0;
        // setShippingCharge(res.data.shippingCharge);
        const promises = resp.map((item) => {
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                res.data[0]?.parameters.forEach((param) => {
                  const weightParameter = param.find(
                    (p) => p.parameterName === "Weight"
                  );

                  if (weightParameter) {
                    isShippingWeight = true;
                    const weightValue = parseFloat(
                      weightParameter.parameterValue.split(" ")[0]
                    ); // Extract weight value
                    totalWeight += weightValue;
                  }
                });
                setIsShippingWeight(isShippingWeight);
                setTotalCartWeight(totalWeight);
                return res1.data[0];
              }
            );
        });

        const variantDataArray = await Promise.all(promises.filter(Boolean));

        setProdVariantsData(variantDataArray);
      } else {
        const cartArray = JSON.parse(localStorage.getItem("guestCartData"));
        setCartCount(cartArray.length);
        const invertedData = cartArray.reverse();
        setcartDetails(invertedData);

        let subAmt = 0;

        const mappedCart = cartArray.map((item) => {
          const totalAmount = item.amount * item.quantity;
          subAmt = subAmt + totalAmount;
        });
        setSubTotal(subAmt);

        const promises = cartArray.map((item) => {
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                return res1.data[0];
              }
            );
        });

        const variantDataArray = await Promise.all(promises.filter(Boolean));

        setProdVariantsData(variantDataArray);
      }
    } catch (error) {
      console.error("Error loading user cart:", error);
    }
  };

  const [toggleUserForm, setToggleUserForm] = useState(false);
  const [toggleLeftSec, setToggleLeftSec] = useState({
    payment: true,
    detail: true,
  });

  const [toggleLoginForm, setToggleLoginForm] = useState(true);

  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [ShipId, setShipId] = useState("");
  const [billId, setBillId] = useState("");
  // const [isShippingType, setShippingType] = useState(false);
  const [chooseDefualtShippAdd, setChooseDefualtShippAdd] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    // console.log("ee", erros);
    setFormErrors(erros);
    setIsSubmit(true);
    setIsDIsplayError(true);
    // console.log("vvv", values);
    // const UserID = localStorage.getItem("NicksCoUser");
    // console.log("UserID", UserID);
    if (Object.keys(erros).length === 0) {
      const res = await createUserShippingAddress({
        ...values,
        userId: UserID,
      })
        .then((res) => {
          // console.log("res", res.data);
          const addressId = res.data._id;
          // if (defaultAdd) {
          updateDefaultAddress(UserID, addressId)
            .then((res1) => {
              console.log("res in update default address", res1.data);
            })
            .catch((err) => {
              console.log("error in default address", err);
            });
          // }
          handleToggleLeftSec(1);
          setValues(initialState);
          setShipId(addressId);
          setVFN(res.data.firstName);
          setVLN(res.data.lastName);
          setVCN(res.data.companyName);
          setVC(res.data.contactNo);
          setVcity(res.data.city);
          setVZ(res.data.zipCode);
          setVA1(res.data.addressLine1);
          // setVA2(res.data.addressLine2);
          getCountry(res.data.countryId).then((countryRes) => {
            setCountryName(countryRes.data.CountryName);
          });
          getState(res.data.stateId).then((StateRes) => {
            setStateName(StateRes.data.StateName);
          });
          // setmodal_list(!modal_list);
          // setValues(initialState);
          setIsDIsplayError(false);
          setChooseDefualtShippAdd(addressId);
          loadAllShippingAddress();

          // setDefaultAdd(false);
          setIsSubmit(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [billa, setBilla] = useState(0);

  const handleCreateBillingAddress = (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validateBA(valuesBA);
    setFormErrorsBA(erros);
    setIsSubmitBA(true);
    setIsDIsplayErrorBA(true);

    getDefaultShippingAddressByUser(UserID)
      .then((res) => {
        if (res.data.isOk) {
          const response = res.data.data;
          if (Object.keys(erros).length === 0) {
            createUserBillingAddress({ ...valuesBA, shippingId: response._id })
              .then((res1) => {
                console.log("res in billing create", res1.data);
                updateDefaultBillingAddress(UserID, res1.data._id)
                  .then((response) => {
                    console.log("update default add", response.data);
                    setBilla(1);
                    //  toast.success("Billing Address Created/Updated Successfully!");
                  })
                  .catch((error) => {
                    console.log("error in update default billing", error);
                  });
              })

              .catch((err) => {
                console.log("err in create billing address", err);
              });
          }
        } else {
          getDeliveryOption(isDelivery)
            .then((delres) => {
              if (delres.data.IsShippingAddress) {
                toast.error("Please Add Shipping Address!");
              } else {
                if (Object.keys(erros).length === 0) {
                  createUserBillingAddress(valuesBA)
                    .then((res1) => {
                      updateDefaultBillingAddress(UserID, res1.data._id)
                        .then((response) => {
                          console.log("update default add", response.data);
                        })
                        .catch((error) => {
                          console.log("error in update default billing", error);
                        });
                    })

                    .catch((err) => {
                      console.log("err in create billing address", err);
                    });
                }
              }
            })
            .catch((err) => {
              console.log("erorr in response delivery option");
            });
        }
      })
      .catch((err) => {
        console.log("error in getting default shipping address");
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    setFormErrors(erros);
    // setIsSubmit(true);
    setIsDIsplayError(true);

    if (Object.keys(erros).length === 0) {
      updateUserShippingAddress(chooseDefualtShippAdd, values)
        .then((res) => {
          const addressId = res.data._id;
          // if (defaultAdd) {
          updateDefaultAddress(UserID, addressId)
            .then((res1) => {
              console.log("res in update default address", res1.data);
            })
            .catch((err) => {
              console.log("error in default address", err);
            });
          // }
          //handleToggleLeftSec(1);
          setValues(initialState);
          setShipId(addressId);
          setVFN(res.data.firstName);
          setVLN(res.data.lastName);
          setVCN(res.data.companyName);
          setVC(res.data.contactNo);
          setVcity(res.data.city);
          setVZ(res.data.zipCode);
          setVA1(res.data.addressLine1);
          // setVA2(res.data.addressLine2);
          getCountry(res.data.countryId).then((countryRes) => {
            setCountryName(countryRes.data.CountryName);
          });
          getState(res.data.stateId).then((StateRes) => {
            setStateName(StateRes.data.StateName);
          });
          setIsDIsplayError(false);
          setEditShippAddress(false);
          setIsSubmit(false);
          setToggleUserForm(!toggleUserForm);

          setIsChecked(!isChecked);
          handleCheckboxChange();
          // toast.success("Shipping Address Updated Successfully!");
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [errFN, setErrFN] = useState(false);
  const [errCN, setErrCN] = useState(false);
  const [errCID, setErrCID] = useState(false);
  const [errSID, setErrSID] = useState(false);
  const [errCI, setErrCI] = useState(false);
  const [errAL1, setErrAL1] = useState(false);

  const [errZP, setErrZP] = useState(false);

  const validate = (values) => {
    const errors = {};
    const phone =
      /^(?!.*(\d)(-?\1){4})(?!0123456789|1234567890|2345678901|3456789012|4567890123|5678901234|6789012345|7890123456|8901234567|9012345678)\d{10}$/;
    if (values.firstName === "") {
      errors.firstName = "First name required!";
      setErrFN(true);
    }
    if (values.firstName !== "") {
      setErrFN(false);
    }
    // else {
    //   setErrFN(false);
    // }

    if (values.contactNo === "") {
      errors.contactNo = "Contact number required!";
      setErrCN(true);
    } else if (!phone.test(values.contactNo)) {
      errors.contactNo = "This is not a valid Contact Number";
      setErrCN(true);
    }
    if (values.contactNo !== "") {
      setErrCN(false);
    }

    if (values.city === "") {
      errors.city = "Select City required!";
      setErrCI(true);
    }
    if (values.city !== "") {
      setErrCI(false);
    }

    if (values.countryId == "") {
      errors.countryId = "Select country name!";
      setErrCID(true);
    }
    if (values.countryId !== "") {
      setErrCID(false);
    }

    if (values.stateId == "") {
      errors.stateId = "Select state name!";
      setErrSID(true);
    }
    if (values.stateId !== "") {
      setErrSID(false);
    }

    if (values.addressLine1 == "") {
      errors.Address = "Address is Required!";
      setErrAL1(true);
    }
    if (values.addressLine1 !== "") {
      setErrAL1(false);
    }

    if (values.zipCode == "") {
      errors.zipCode = "zipCode is Required!";
      setErrZP(true);
    }
    if (values.zipCode !== "") {
      setErrZP(false);
    }

    return errors;
  };

  const [agree, setAgree] = useState(false);

  const [isDelivery, setIsDelivery] = useState(1);

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    console.log("ee", name, value);
    setIsDelivery(value);
    // setDateValidate(true);
    setDateValidate(false);
    getDeliveryOption(value).then((res) => {
      console.log("res in delivery", res.data);
      setShippingCharge(res.data.DeliveryCharge);
      if (!res.data.IsShippingAddress) {
        setDateValidate(true);
      }
      if (res.IsFreeShippingOverWeight) {
      }
    });
    // setIsDelivery(parseInt(value));
  };

  const [checkoutError, setCheckoutError] = useState(false);
  const [checkoutMessage, setCheckoutMessage] = useState("");

  const handleCheckout = async () => {
    let ShipId = "";
    let billId = "";

    if (billa === 0 && !isChecked) {
      toast.error("Please modify billing address");
      return;
    };

    if (isDelivery === 1) {
      toast.error("Please select delivery option");
      return;
    };

    try {
      setIsSpinner(true);
      const defaultShippingAddressResponse =
        await getDefaultShippingAddressByUser(UserID);

      const defaultsa = defaultShippingAddressResponse.data;
      if (defaultsa.isOk) {
        setCheckoutError(false);
        setCheckoutMessage("");

        ShipId = defaultsa.data._id;
        getDeliveryOption(isDelivery)
          .then((res) => {
            if (res.data.IsShippingAddress) {
              ShipId = defaultsa.data._id;
              console.log("in");
            } else {
              ShipId = null;
            }
          })
          .catch((err) => {
            console.log("error in get delivery option", err);
            setIsSpinner(false);
          });
      } else {
        setCheckoutError(true);
        getDeliveryOption(isDelivery)
          .then((res) => {
            if (res.data.IsShippingAddress) {
              setCheckoutMessage("Please Add Shipping Address!");
            } else {
              ShipId = null;
            }
          })
          .catch((err) => {
            console.log("error in get delivery option", err);
            setIsSpinner(false);
          });
      }

      const defaultBillingAdd = await getDefaultBillingAddressByUser(UserID);
      const defaultba = defaultBillingAdd.data;
      if (defaultba.isOk) {
        setCheckoutError(false);
        setCheckoutMessage("");
        billId = defaultba.data._id;
      } else {
        setCheckoutError(true);
        toast.error("Please Add Billing Address!");
        setCheckoutMessage("Please Add Billing Address!");
        setIsSpinner(false);
      }

      // if (dateValidate) {

      // if (dateValidate && deliveryData.deliveryTime === null) {
      //   toast.error("Select Delivery Time");
      //   setIsSpinner(false);
      // } else

      if (paymentMethod !== "cardPayment") {
        setPaypal2(true);
      }

      if (selectedCreditCard == null && paymentMethod === "cardPayment") {
        toast.error("Please select credit card!");
        setIsSpinner(false);
        return;
      } else if (
        (dateValidate && deliveryData.deliveryAddress === null) ||
        deliveryData.deliveryAddress === "Select Address"
      ) {
        toast.error("Select Delivery Address");
        console.log("deliveryData", deliveryData);
        setIsSpinner(false);
      } else {
        console.log("deliveryData", deliveryData);
        const desiredFields = [
          "userId",
          "productId",
          "productVariantsId",
          "quantity",
          "subsId",
          "productComment",
        ];

        const products = cartDetails.map((item) => {
          const product = {};
          desiredFields.forEach((field) => {
            if (item[field]) {
              product[field] = item[field];
            }
          });
          return product;
        });

        const data = {
          userId: UserID,
          selectedCreditCard:
            paymentMethod == "cardPayment" ? selectedCreditCard : null,
          shippingAddress: ShipId,
          orderComment: orderComment,
          billingAddress: billId,
          DeliveryType: isDelivery,
          shippingCharge: shippingCharge,
          deliveryData: deliveryData,
          products: products,
          tip: tipPoint ? tipPoint : 0,
          promoCode: promoCode ? promoCode : "",
          loyaltyMasterId: promoDetails ? promoDetails._id : null,
          usedLoyaltyPoints: loyaltypoints ? loyaltypoints : 0,
          creditedLoyaltyPoints: creditedLoyaltyPoints
            ? parseFloat(creditedLoyaltyPoints) +
            parseFloat(productPoints) +
            parseFloat(promoLoyaltypoints)
            : parseFloat(productPoints) + parseFloat(promoLoyaltypoints) || 0.0,
          discount: discount
            ? parseFloat(discount) +
            parseFloat(productDiscount) +
            parseFloat(promoDiscount)
            : parseFloat(productDiscount) + parseFloat(promoDiscount) || 0.0,
        };

        let orderResponse = "";
        if (cartDetails.length > 0) {
          if (paymentMethod == "cardPayment") {
            orderResponse = await createOrderInOneGoHeartLand(data);
            setIsSpinner(false);
            setDateValidate(false);
            window.location.replace("/thankyou");
          } else if (paymentMethod == "paypalPayment") {
            orderResponse = await createOrderInOneGo(data);
            setIsSpinner(false);
            setDateValidate(false);

            //window.open(orderResponse.data.URL, '_blank');
            //window.location.replace(orderResponse.data.URL); //

            setTimeout(function() {
              document.location.href = orderResponse.data.URL;
            }, 350);


          }
        } else {
          toast.error("Your Cart seems to be Empty");
          setIsSpinner(false);
        }
      }
    } catch (error) {
      toast.error("Error while placing order - Please verify card details");
      console.log("Error:", error);
      setIsSpinner(false);
    }
  };
  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };

  const validClassCountryName =
    errCN && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassCityName =
    errCI && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassStateName =
    errSID && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassAdd =
    errAL1 && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassFN =
    errFN && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassContact =
    errCN && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassZip =
    errZP && isDisplayError ? "form-control is-invalid" : "form-control";

  // billing address VALIDATION

  const [errFNBA, setErrFNBA] = useState(false);
  const [errCNBA, setErrCNBA] = useState(false);
  const [errCIDBA, setErrCIDBA] = useState(false);
  const [errSIDBA, setErrSIDBA] = useState(false);
  const [errCIBA, setErrCIBA] = useState(false);
  const [errAL1BA, setErrAL1BA] = useState(false);

  const [errZPBA, setErrZPBA] = useState(false);

  const validateBA = (valuesBA) => {
    const errors = {};
    const phoneBA =
      /^(?!.*(\d)(-?\1){4})(?!0123456789|1234567890|2345678901|3456789012|4567890123|5678901234|6789012345|7890123456|8901234567|9012345678)\d{10}$/;
    if (valuesBA.firstNameBA === "") {
      errors.firstNameBA = "First name required!";
      setErrFNBA(true);
    }
    if (valuesBA.firstNameBA !== "") {
      setErrFNBA(false);
    }
    // else {
    //   setErrFN(false);
    // }

    if (valuesBA.contactNoBA === "") {
      errors.contactNoBA = "Contact number required!";
      setErrCNBA(true);
    } else if (!phoneBA.test(valuesBA.contactNoBA)) {
      errors.contactNoBA = "This is not a valid Contact Number";
      setErrCNBA(true);
    }
    if (valuesBA.contactNoBA !== "") {
      setErrCNBA(false);
    }

    if (valuesBA.cityBA === "") {
      errors.cityBA = "Select City required!";
      setErrCIBA(true);
    }
    if (valuesBA.cityBA !== "") {
      setErrCIBA(false);
    }

    if (valuesBA.countryIdBA == "") {
      errors.CountryIDBA = "Select country name!";
      setErrCIDBA(true);
    }
    if (valuesBA.countryIdBA !== "") {
      setErrCIDBA(false);
    }

    if (valuesBA.stateIdBA == "") {
      errors.stateIdBA = "Select state name!";
      setErrSIDBA(true);
    }
    if (valuesBA.stateIdBA !== "") {
      setErrSIDBA(false);
    }

    if (valuesBA.addressLine1BA == "") {
      errors.addressLine1BA = "Address is Required!";
      setErrAL1BA(true);
    }
    if (valuesBA.addressLine1BA !== "") {
      setErrAL1BA(false);
    }

    if (valuesBA.zipCodeBA == "") {
      errors.zipCodeBA = "zipCode is Required!";
      setErrZPBA(true);
    }
    if (valuesBA.zipCodeBA !== "") {
      setErrZPBA(false);
    }

    return errors;
  };

  const validClassCountryNameBA =
    errCNBA && isDisplayErrorBA ? "form-control is-invalid" : "form-control";
  const validClassCityNameBA =
    errCIBA && isDisplayErrorBA ? "form-control is-invalid" : "form-control";
  const validClassStateNameBA =
    errSIDBA && isDisplayErrorBA ? "form-control is-invalid" : "form-control";

  const validClassAddBA =
    errAL1BA && isDisplayErrorBA ? "form-control is-invalid" : "form-control";

  const validClassFNBA =
    errFNBA && isDisplayErrorBA ? "form-control is-invalid" : "form-control";

  const validClassContactBA =
    errCNBA && isDisplayErrorBA ? "form-control is-invalid" : "form-control";
  const validClassZipBA =
    errZPBA && isDisplayErrorBA ? "form-control is-invalid" : "form-control";

  const [editShippAddress, setEditShippAddress] = useState(false);
  const [orderComment, setOrderComment] = useState("");

  const handleOrderComment = (event) => {
    setOrderComment(event.target.value);
  };

  const handleToggleUserForm = () => {
    setToggleUserForm(!toggleUserForm);

    //setToggleLeftSec((prevState) => ({
    //  payment: false,
    //  detail: false,
    //}));

    getUserShippingAddress(chooseDefualtShippAdd).then((res) => {
      // console.log("res in get user shipping", res.data);
      setValues({
        ...values,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        contactNo: res.data.contactNo,
        companyName: res.data.companyName,
        city: res.data.city,
        stateId: res.data.stateId,
        countryId: res.data.countryId,
        addressLine1: res.data.addressLine1,
        addressLine2: res.data.addressLine2,
        zipCode: res.data.zipCode,
        userId: res.data.userId,
        IsActive: res.data.IsActive,
        isBillingSame: res.data.isBillingSame,
      });
    });

    setEditShippAddress(true);
  };

  const handleAddNewAddress = () => {
    setToggleUserForm(!toggleUserForm);

    setToggleLeftSec((prevState) => ({
      payment: false,
      detail: false,
    }));
  };

  const [isContinue, setIsContinue] = useState(true);

  const handleContinue = () => {
    // setToggleUserForm(!toggleUserForm);

    // setToggleLeftSec((prevState) => ({
    //   payment: false,
    //   detail: false,
    // }));

    // setToggleLeftSec((prevState) => ({
    //   payment: false,
    //   detail: true,
    // }));

    updateDefaultAddress(UserID, chooseDefualtShippAdd)
      .then((res1) => {
        setIsContinue(false);
        // console.log("res cont in update default address", res1.data);
      })
      .catch((err) => {
        console.log("error in default address", err);
      });

    handleCheckboxChange(null, true);

    //  toast.success("Next, please select a delivery option");

  };

  const handleToggleLeftSec = (status) => {
    // console.log("status", status);
    if (status === 1) {
      setToggleLeftSec((prevState) => ({
        payment: false,
        detail: true,
      }));
      // console.log("detailsslslsl");
    } else if (status === 2) {
      setToggleLeftSec((prevState) => ({
        payment: true,
        detail: false,
      }));
      // console.log("paymentsttst");
    } else if (status === 3) {
      setToggleLeftSec((prevState) => ({
        detail: false,
      }));
    } else if (status === 4) {
      setToggleLeftSec((prevState) => ({
        payment: false,
      }));
      // console.log("paymentsttst 333");
    }

    setToggleUserForm(false);
  };

  const handleDeilvery = (status) => {
    // console.log("ddddd", deliveryData);
    // if (dateValidate && deliveryData.deliveryTime < minDate) {
    //   toast.error("Please select a valid time");
    // } else
    if (isDelivery === 1) {
      toast.error("Please select a delivery option by clicking the small circle on the left side");
      return;
    }

    if (
      (dateValidate && deliveryData.deliveryAddress === null) ||
      deliveryData.deliveryAddress === "Select Address"
    ) {
      toast.error("please select pickup address");
    } else {
      if (status === 2) {
        setToggleLeftSec((prevState) => ({
          payment: true,
          detail: true,
        }));
        // console.log("paymentsttst");
      }
      // if (isDelivery === 1) {
      //   setShippingType(true);
      // }
      setToggleUserForm(false);

      //  toast.success("Next, please select payment method");
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  // const [billingID, setBillingId] = useState(false);

  const handleCheckboxChange = (e, pr) => {
    setIsChecked(!isChecked);

    if (e?.target.checked || pr === true) {
      // console.log("shi", ShipId);
      if (chooseDefualtShippAdd) {
        // console.log("ddd", defualtShipId);
        updateBillingValueSA(chooseDefualtShippAdd).then((res) => {
          // console.log("res in update", res.data);
          const billingCheck = res.data.isBillingSame;
          if (billingCheck) {
            getDefaultShippingAddressByUser(UserID)
              .then((resp) => {
                // console.log("resp in default shipping", resp.data);
                const response = resp.data.data;
                if (resp.data.isOk && response.billingId === null) {
                  const newValuesBA = {
                    // ...valuesBA,
                    firstNameBA: response.firstName,
                    lastNameBA: response.lastName,
                    contactNoBA: response.contactNo,
                    companyNameBA: response.companyName,
                    cityBA: response.city,
                    stateIdBA: response.stateId,
                    countryIdBA: response.countryId,
                    addressLine1BA: response.addressLine1,
                    addressLine2BA: response.addressLine2,
                    zipCodeBA: response.zipCode,
                    userIdBA: response.userId,
                    IsActiveBA: response.IsActive,
                    isBillingSameBA: response.isBillingSame,
                    shippingId: response._id,
                  };
                  // console.log("newValuesBA", newValuesBA);

                  setValuesBA(newValuesBA); // Update valuesBA state

                  // After updating the state, create the user billing address
                  createUserBillingAddress(newValuesBA)
                    .then((res1) => {
                      // console.log("res in billing", res1.data);
                      // setBillingId(res1.data._id);

                      const billid = res1.data._id;
                      // console.log("bild", billid);
                      const _id = response._id;
                      updatebillingIdSA(_id, billid)
                        .then((updRes) => {
                          console.log("res in billing id", updRes.data);
                        })
                        .catch((error) => {
                          console.log("error in update billing id", error);
                        });

                      updateDefaultBillingAddress(UserID, res1.data._id)
                        .then((response1) => {
                          console.log("update default add", response1.data);
                        })
                        .catch((error) => {
                          console.log("error in update default billing", error);
                        });
                    })

                    .catch((err) => {
                      console.log("err in create billing address", err);
                    });
                } else {
                  // setBillingId(response.billingId);
                  updateDefaultBillingAddress(UserID, response.billingId)
                    .then((response1) => {
                      console.log("update default add", response1.data);
                    })
                    .catch((error) => {
                      console.log("error in update default billing", error);
                    });
                }
              })
              .catch((err) => {
                console.log("error in get defualt shippin add");
                toast.error("Please Add Shipping Address!");
              });
          }
        });
      } else {
        toast.error("Please Add Default/Account Address!");
      }
    } else {
      getDefaultBillingAddressByUser(UserID)
        .then((res) => {
          const response = res.data.data;
          if (res.data.isOk) {
            removeUserBillingAddress(response._id)
              .then((res) => {
                console.log("res in remove billing", res);
              })
              .catch((err2) => {
                console.log("error in remove billing address");
              });
          }
        })
        .catch((err) => {
          console.log("error in default billing address", err);
        });
    }
  };

  const [togglePromoBtn, setTogglePromoBtn] = useState({
    promo: false,
    note: false,
    tip: false,
  });

  const handlePromoBtn = () => {
    if (togglePromoBtn.promo == true) {
      setTogglePromoBtn({
        promo: false,
        note: false,
        tip: false,
      });
    } else {
      setTogglePromoBtn({
        promo: true,
        note: false,
        tip: false,
      });
    }
  };
  const handleNoteBtn = () => {
    if (togglePromoBtn.note == true) {
      setTogglePromoBtn({
        promo: false,
        note: false,
        tip: false,
      });
    } else {
      setTogglePromoBtn({
        promo: false,
        note: true,
        tip: false,
      });
    }
  };

  const handleTipBtn = () => {
    if (togglePromoBtn.tip == true) {
      setTogglePromoBtn({
        promo: false,
        note: false,
        tip: false,
      });
    } else {
      setTogglePromoBtn({
        promo: false,
        note: false,
        tip: true,
      });
    }
  };

  const handleChangeAddress = (e) => {
    // console.log("change add", e.target.value);
    setIsContinue(true);
    setChooseDefualtShippAdd(e.target.value);
    getUserShippingAddress(e.target.value).then((res) => {
      // console.log("res in get user shipping", res.data);
      setVFN(res.data.firstName);
      setVLN(res.data.lastName);
      setVCN(res.data.companyName);
      setVC(res.data.contactNo);
      setVcity(res.data.city);
      setVZ(res.data.zipCode);
      setVA1(res.data.addressLine1);
      // setVA2(res.data.addressLine2);
      setChooseDefualtShippAdd(res.data._id);
      getCountry(res.data.countryId).then((countryRes) => {
        // console.log("res in country", countryRes.data);
        setCountryName(countryRes.data.CountryName);
      });
      getState(res.data.stateId).then((StateRes) => {
        // console.log("res in state", StateRes.data);
        setStateName(StateRes.data.StateName);
      });
    });

    console.log("choose defualt add", e.target.value);
    if (e.target.value !== "Select Address") {

      updateDefaultAddress(UserID, chooseDefualtShippAdd)
        .then((res1) => {
          setIsContinue(false);
          //  toast.success("Default address updated successfully");
        })
        .catch((err) => {
          console.log("error in default address", err);
        });

    }

  };

  const handleChangeOption = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    // console.log("ddd", UserID);
  };

  const handleChangeOptionBA = (e) => {
    setValuesBA({
      ...valuesBA,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePromo = (e) => {
    setPromocode(e.target.value);
    setPromo(false);
    setPromoDetails();
    setPromoDiscount(0);
    setCreditedLoyaltyPoints(0);
    setPromoLoyaltypoints(0);
    setNotValidPromo(false);
  };

  useEffect(() => {
    listTipMaster().then((res) => {
      setTipList(res.data);
      // console.log("tip", res.data);
    });
  }, []);

  useEffect(() => {
    listPromo().then((res) => {
      setPromoList(res.data.res);
      // console.log("promosss",res.data.res)
    });
  }, []);

  useEffect(() => {
    getPointsConversation().then((res) => {
      setPointsPerDollar(res.data.points);
    });
  }, []);

  const handleApplyPromo = (e) => {
    e.preventDefault();
    checkPromoExists(promoCode).then((res) => {
      if (res.data.isOk) {
        // console.log(res.data.res);
        setPromoDetails(res.data.res);
        getPointTransactionByUserId(UserID, res.data.res._id).then((point) => {
          // console.log("point", point);
          if (!point.data.isOk) {
            setNotValidPromo(true);
            // toast.error("Promo Code already used before!");
          } else {
            if (res.data.res.minOrderAmount > 0) {
              if (
                subTotal == res.data.res.minOrderAmount ||
                subTotal > res.data.res.minOrderAmount
              ) {
                toast.success("Promo Code Applided");
                setNotValidPromo(false);
                setPromo(true);
                if (res.data.res.type == "Credit") {
                  setCreditedLoyaltyPoints(res.data.res.points);
                } else if (res.data.res.type == "Debit") {
                  setPromoLoyaltypoints(res.data.res.points);
                  let countPoints = res.data.res.points / pointsPerDollar;
                  // countPoints = countPoints / 10;
                  let totalAfterLoyaltyPoint =
                    subTotal > countPoints ? subTotal - countPoints : subTotal;
                  // setTotalAmount(totalAfterLoyaltyPoint);
                  setPromoDiscount(subTotal > countPoints ? countPoints : 0);
                  // setIsLoyaltypoints(false);
                }
              } else {
                toast.error(
                  "Sub total amount should be more than $" +
                  res.data.res.minOrderAmount +
                  " to apply this offer."
                );
              }
            } else {
              toast.success("Promo Code Applided");
              setNotValidPromo(false);
              setPromo(true);
              if (res.data.res.type == "Credit") {
                setCreditedLoyaltyPoints(res.data.res.points);
              } else if (res.data.res.type == "Debit") {
                setPromoLoyaltypoints(res.data.res.points);
                let countPoints = res.data.res.points / pointsPerDollar;
                // countPoints = countPoints / 10;
                let totalAfterLoyaltyPoint =
                  subTotal > countPoints ? subTotal - countPoints : subTotal;
                totalAfterLoyaltyPoint =
                  totalAfterLoyaltyPoint - productDiscount;

                // setTotalAmount(totalAfterLoyaltyPoint);
                setPromoDiscount(subTotal > countPoints ? countPoints : 0);
                // setIsLoyaltypoints(true);
              }
            }
          }
        });
      } else {
        setPromo(false);
        toast.error("Promo Code not Exists!");
        // alert("Not found!");
      }
    });
  };

  const handleRemovePromo = (e) => {
    e.preventDefault();
    setPromo(false);
    setPromoDetails();
    setPromocode("");
    setDiscount(0);
    setPromoDiscount(0);
    setCreditedLoyaltyPoints(0);
    setPromoLoyaltypoints(0);
    setNotValidPromo(false);
    // setTotalAmount(subTotal - productDiscount);
  };

  useEffect(() => {
    getUsers(UserID).then((res) => {
      setPoints(res.data.points);
    });
  }, []);

  const handelApplyPoint = (e) => {
    e.preventDefault();
    let countPoints = loyaltypoints / pointsPerDollar;
    let totalAfterLoyaltyPoint =
      subTotal > countPoints ? subTotal - countPoints : subTotal;
    // totalAfterLoyaltyPoint = totalAfterLoyaltyPoint - productDiscount;
    // setTotalAmount(totalAfterLoyaltyPoint);
    setDiscount(subTotal > countPoints ? countPoints : 0);
    setIsLoyaltypoints(true);
  };

  const handelRemovePoint = (e) => {
    e.preventDefault();
    // setTotalAmount(subTotal);
    setDiscount(0);
    setLoyaltypoints(0);
    setIsLoyaltypoints(false);
    setCreditedLoyaltyPoints(0);
  };

  const handleApplyTip = () => {
    // e.preventDefault();
    // console.log("tipstring", tipPoint);

    const typePR = tipPoint.match(/%/);
    const type$ = tipPoint.match(/\$/);

    const tipAmount = parseFloat(tipPoint);
    // console.log("$",type$[0], "%%%%", tipAmount);

    if (typePR && typePR[0] == "%") {
      setAddTip(((subTotal * tipAmount) / 100).toFixed(2));
      setIsTip(true);
      // console.log("in %");
    } else if (type$ && type$[0] == "$") {
      setAddTip(tipAmount);
      setIsTip(true);
      // console.log("in $");
    } else {
      setAddTip(tipAmount);
      setTipPoint(tipAmount);
      setIsTip(true);
    }
  };

  useEffect(() => {
    getProductPoints(cartDetails).then((res) => {
      setProductPoints(res.data.totalPoints);
      setProductDiscount(res.data.totalDiscount);
      let totalAfterPoint = subTotal - res.data.totalDiscount;
      // totalAfterLoyaltyPoint = totalAfterLoyaltyPoint - productDiscount;
      // setTotalAmount(totalAfterPoint);
    });
  }, [cartDetails]);

  useEffect(() => {
    setTotalAmount(
      subTotal - productDiscount - promoDiscount - discount + parseFloat(addTip)
    );
  }, [subTotal, productDiscount, discount, promoDiscount, addTip]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1025, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [isSubs, setIsSubs] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      for (const item of cartDetails) {
        if (item.subsId !== null) {
          setIsSubs(true);
          setPaymentMethod("cardPayment");
          break;
        }
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [cartDetails]);

  const [paymentMethod, setPaymentMethod] = useState("cardPayment");
  const [isPaypal, setPaypal] = useState(false);
  const [cmsPaypalContent, setCmsPaypalContent] = useState([]);

  const [paypal2, setPaypal2] = useState(false);

  const handlePayment = (e) => {

    setPaymentMethod(e.target.value);
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    if (paymentMethod == "paypalPayment" && isSubs) {
      setPaypal(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    listContent()
      .then((res) => {
        setCmsPaypalContent(res.data);
      })
      .catch((err) => {
        setCmsPaypalContent();
      });
  }, []);

  const handleExpChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    if (value.length === 1) {
      // Only accept 0 or 1 as the first character
      if (value !== '0' && value !== '1') {
        return;
      }
    }

    if (value.length === 2) {
      // Only accept 0-9 as the second character
      if (!/[0-9]/.test(value[1])) {
        return;
      }
      // Ensure the month is valid (01-12)
      if (parseInt(value, 10) > 12) {
        return;
      }
      if (parseInt(value, 10) === 0) {
        return;
      }
    }

    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }

    if (value.length === 5) {
      // Ensure the year part is a valid two-digit number
      const month = parseInt(value.slice(0, 2), 10);
      const year = parseInt(value.slice(3), 10);
      const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
      const currentMonth = new Date().getMonth() + 1; // Get current month (0-based index)

      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        setShower(true);
      } else {
        setShower(false);

        const [montho, yearo] = value.split('/');
        const fullYear = `20${yearo}`;

        const formattedDate = `${fullYear}-${montho}`;

        setCardValues({
          ...cardValues,
          expiryDate: formattedDate,
        })

      }
    }

    setExpirationdate(value);

  };

  const [selecto, setSelecto] = useState(0);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <ToastContainer
            position="top-center"
          />

          <Cart />
          <Hader Color="#000" />
          <MobileHeader />
          <section
            className="cart_section sec_ptb_120 bg_default_gray"
            style={{ marginTop: "100px" }}
          >
            <Container>
              <Row className="d-flex justify-content-center">
                <Col
                  lg={6}
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  {/* <div className="container-border">
                    <div className="title">Express Checkout</div>
                    <div className="container-buttons">
                      <button
                        onClick={handleCheckout}
                        disabled={
                          !agree ||
                          parseFloat(totalAmount) + parseFloat(shippingCharge) <
                            1 ||
                          isSpinner
                        }
                      >
                        <SlPaypal />
                        {isSpinner ? (
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <TailSpin
                              visible={true}
                              height="20"
                              width="30"
                              color="#fff"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </span>
                        ) : (
                          <span>Paypal</span>
                        )}
                      </button>
                      <button>
                        <FaGoogle /> pay
                      </button>
                    </div>
                  </div>
                  <div className="centered-text">
                    <p>- or continue below -</p>
                  </div> */}

                  <div className="order-card-header">
                    <h5>
                      {" "}
                      <CiLock /> Secure Checkout
                    </h5>
                  </div>
                  <hr />

                  {/* login */}
                  {!isUserID && (
                    <Card className="p-3">
                      <CardHeader className="card-header-flex">
                        <h6>
                          <p style={{ textAlign: "end" }}>
                            Don’t have an account ?
                            <span>
                              <Link className="signUpLink" to="/register">
                                <u>Register</u>
                              </Link>
                            </span>
                          </p>
                        </h6>
                      </CardHeader>
                      {toggleLoginForm && (
                        <Card
                          style={{
                            padding: "10px",
                            width: "90%",
                            backgroundColor: "#fff",
                            border: "1px solid grey",
                            borderRadius: "5px",
                            margin: "20px",
                          }}
                        >
                          <h1 className="text-center">Login</h1>

                          <form>
                            <div className="mb-3">
                              <input
                                placeholder="Enter Your Email"
                                type="email"
                                name="email"
                                value={email}
                                style={{ marginBottom: 0 }}
                                className={validClassEmail}
                                onChange={handleChangeLoginValues}
                              />
                              {isSubmit && (
                                <p
                                  className="text-danger"
                                  style={{ textAlign: "left" }}
                                >
                                  {formErrors.email}
                                </p>
                              )}
                            </div>
                            <div className="mb-3">
                              <input
                                placeholder="Enter Your Password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={handleChangeLoginValues}
                                style={{ marginBottom: 0 }}
                                className={validClassPassword}
                              />
                              {isSubmit && (
                                <p
                                  className="text-danger"
                                  style={{ textAlign: "left" }}
                                >
                                  {formErrors.password}
                                </p>
                              )}
                            </div>

                            <div className="billing-btn-div mt-4">
                              <Button
                                className="btn btn-dark mt-2 wid"
                                onClick={() =>
                                  setLoginValues(initialLoginState)
                                }
                              >
                                Cancel
                              </Button>

                              <Button
                                type="submit"
                                className="btn btn-dark mt-2 wid"
                                onClick={handleLogin}
                              >
                                Login
                              </Button>
                            </div>
                          </form>
                        </Card>
                      )}
                    </Card>
                  )}

                  <Card style={{ marginBottom: "15px" }} className="p-3">
                    <CardHeader className="card-header-flex">
                      <h6>Delivery method <span style={{ color: "red" }}>*</span></h6>


                      {/* {!toggleLeftSec.detail && (
                        <FaAngleDown onClick={() => handleToggleLeftSec(1)} />
                      )}
                      {toggleLeftSec.detail && (
                        <FaAngleUp onClick={() => handleToggleLeftSec(3)} />
                      )} */}

                    </CardHeader>
                    {toggleLeftSec.detail && (
                      <Form onSubmit={handleSubmit}>
                        {deliveryOptionsData.map((item, index) => (
                          <Row key={index}>
                            {!item.IsSubsAllow && isSubs ? null : (
                              <Col lg={12}>
                                <Label>{item.DeliveryType}</Label>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    border: "1px solid #c7a17a",
                                    width: "100%",
                                    alignItems: "center",
                                    padding: "15px",
                                  }}
                                >
                                  <Input
                                    type="radio"
                                    name="delivery"
                                    value={item._id}
                                    checked={isDelivery === item._id}
                                    onChange={handleRadioChange}
                                  />
                                  <div style={{ width: "90%" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <p style={{ marginBottom: "auto" }}>
                                        {item.DeliveryTypeName}
                                      </p>
                                      <p style={{ marginBottom: "auto" }}>
                                        {item.IsFreeShippingOverAmount ? (
                                          <span>
                                            {item.FreeShippingAmount <=
                                              subTotal ? (
                                              <span>$ 0.00</span>
                                            ) : (
                                              <span>
                                                ${item.DeliveryCharge}.00
                                              </span>
                                            )}
                                          </span>
                                        ) : item.IsFreeShippingOverWeight ? (
                                          <span>
                                            {parseFloat(
                                              item.FreeShippingWeight.split(
                                                " "
                                              )[0]
                                            ) <= totalCartWeight ? (
                                              <span>$ 0.00</span>
                                            ) : (
                                              <span>
                                                ${item.DeliveryCharge}.00
                                              </span>
                                            )}
                                          </span>
                                        ) : (
                                          <span>${item.DeliveryCharge}.00</span>
                                        )}
                                      </p>
                                    </div>
                                    {!item.IsShippingAddress ? (
                                      <select
                                        name="DeliveryAddress"
                                        // className={validClassDeliveryAddress}
                                        className="form-control"
                                        onChange={handleDeliveryAddress}
                                        value={deliveryData.deliveryAddress}
                                        data-choices
                                        data-choices-sorting="true"
                                      >
                                        <option>Select Address</option>

                                        {companyData.map((company) => {
                                          return item.DeliveryAddress.map(
                                            (address) => {
                                              if (company._id === address) {
                                                return (
                                                  <React.Fragment
                                                    key={company._id}
                                                  >
                                                    <option
                                                      value={company._id}
                                                      style={{ color: "grey" }}
                                                    >
                                                      {company.Address}
                                                    </option>
                                                  </React.Fragment>
                                                );
                                              } else {
                                                return null; // Return null if the condition is not met
                                              }
                                            }
                                          );
                                        })}
                                      </select>
                                    ) : null}
                                    {/* {!item.IsShippingAddress &&
                                    item.StartDate &&
                                    item.EndDate ? (
                                      <p style={{ color: "grey" }}>
                                        Please Pick Between{" "}
                                        {moment(item.StartDate).format(
                                          "hh:mm a"
                                        )}{" "}
                                        to{" "}
                                        {moment(item.EndDate).format("hh:mm a")}
                                        .
                                      </p>
                                    ) : null} */}
                                    {/* pick up time and dine time selection */}
                                    {/* {!item.IsShippingAddress ? (
                                      <p style={{ color: "grey" }}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DemoContainer
                                            components={[
                                              "DateTimePicker",
                                              "DateTimePicker",
                                            ]}
                                          >
                                            <Row>
                                              <Col lg={6} className="mt-2">
                                                {item.DeliveryType ===
                                                "Free Pick-up" ? (
                                                  <DateTimePicker
                                                    label="Pickup Time"
                                                    key={index}
                                                    value={
                                                      deliveryData.deliveryTime &&
                                                      deliveryData.deliveryTime[
                                                        index
                                                      ]
                                                    }
                                                    onChange={(st) =>
                                                      handleStartTimeChange(
                                                        st,
                                                        item,
                                                        index
                                                      )
                                                    }
                                                    inputVariant="outlined"
                                                    InputProps={{
                                                      style: { width: "100%" },
                                                    }}
                                                    minDate={minDate}
                                                  />
                                                ) : (
                                                  item.DeliveryType ===
                                                    "Dine In" && (
                                                    <DateTimePicker
                                                      label="Dine Time"
                                                      key={index}
                                                      value={
                                                        deliveryData.deliveryTime &&
                                                        deliveryData
                                                          .deliveryTime[index]
                                                      }
                                                      onChange={(st) =>
                                                        handleStartTimeChange(
                                                          st,
                                                          item,
                                                          index
                                                        )
                                                      }
                                                      minDate={minDate}
                                                      inputVariant="outlined"
                                                      InputProps={{
                                                        style: {
                                                          width: "100%",
                                                        },
                                                      }}
                                                      maxDate={maxDate}
                                                      format="hh:mm"
                                                    />
                                                  )
                                                )}
                                              </Col>
                                            </Row>
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </p>
                                    ) : null} */}
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>
                        ))}

                        {/* <div id="pickupdetail" style={{ padding: "15px" }}>
                          <p>Pickup instructions</p>
                          <p style={{ color: "grey" }}>
                            Please Pick Between M-F 10:00 am to 2:00 pm. We
                            Deliver only Roasted Coffee 2 lb or more Order.
                          </p>
                        </div> */}
                        <Row>

                          <Button
                            hidden
                            className="btn btn-dark mt-2"
                            onClick={() => handleDeilvery(2)}
                          >
                            Save & continue
                          </Button>{" "}
                        </Row>
                      </Form>

                    )}
                  </Card>

                  <Card hidden={isDelivery === "66904c7edb027e7bb9bbee7f" || isDelivery === 1} className="p-3">
                    <CardHeader className="card-header-flex">
                      <h6>Delivery details</h6>
                      {/* <p
                    onClick={handleToggleUserForm}
                    style={{ display: `${toggleUserForm ? "none" : "block"}` }}
                  >
                    <u>Change</u>
                  </p> */}
                    </CardHeader>
                    {toggleUserForm && (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <FormGroup>
                              <Label for="firstName" className="label">
                                First Name*
                              </Label>
                              <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                className={validClassFN}
                                value={values.firstName}
                                onChange={handleChangeOption}
                              />
                              {isDisplayError && (
                                <p className="text-danger">
                                  {formErrors.firstName}
                                </p>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg={6}>
                            <FormGroup>
                              <Label for="lastName" className="label">
                                Last Name
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={values.lastName}
                                onChange={handleChangeOption}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>


                          <Col>
                            <FormGroup>
                              <Label for="phone" className="label">
                                Company Name
                              </Label>
                              <Input
                                type="text"
                                name="companyName"
                                id="companyName"
                                value={values.companyName}
                                onChange={handleChangeOption}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup>
                          <Label for="address" className="label">
                            Address*
                          </Label>
                          <Input
                            type="text"
                            name="addressLine1"
                            className={validClassAdd}
                            id="address"
                            value={values.addressLine1}
                            onChange={handleChangeOption}
                          />
                          {isDisplayError && (
                            <p className="text-danger">
                              {formErrors.addressLine1}
                            </p>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="address" className="label">
                            Address - line 2
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="addressLine2"
                            id="address"
                            value={values.addressLine2}
                            onChange={handleChangeOption}
                          />
                        </FormGroup>

                        <Row>

                          <Col hidden>
                            <FormGroup>
                              <Label for="Country" className="label">
                                Country*
                              </Label>

                              <select
                                name="countryId"
                                className={validClassCountryName}
                                onChange={handleChangeOption}
                                value={values.countryId}
                                data-choices
                                data-choices-sorting="true"
                              >
                                <option>Select Country</option>
                                {countries.map((c) => {
                                  return (
                                    <React.Fragment key={c._id}>
                                      {c.isActive && (
                                        <option value={c._id}>
                                          {c.CountryName}
                                        </option>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </select>
                              {isDisplayError && (
                                <p className="text-danger">
                                  {formErrors.countryId}
                                </p>
                              )}
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup>
                              <Label for="State" className="label">
                                city*
                              </Label>
                              <Input
                                type="text"
                                className={validClassCityName}
                                name="city"
                                id="city"
                                value={values.city}
                                onChange={handleChangeOption}
                              />
                              {isDisplayError && (
                                <p className="text-danger">{formErrors.city}</p>
                              )}
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup>
                              <Label for="State" className="label">
                                State*
                              </Label>

                              <select
                                name="stateId"
                                className={validClassStateName}
                                onChange={handleChangeOption}
                                value={values.stateId}
                                data-choices
                                data-choices-sorting="true"
                              >
                                <option>Select State </option>
                                {states.map((s) => {
                                  return (
                                    <React.Fragment key={s._id}>
                                      {s.isActive &&
                                        countryId === s.CountryID && (
                                          <option value={s._id}>
                                            {s.StateName}
                                          </option>
                                        )}
                                    </React.Fragment>
                                  );
                                })}
                              </select>
                              {isDisplayError && (
                                <p className="text-danger">
                                  {formErrors.stateId}
                                </p>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>

                          <Col>
                            <FormGroup>
                              <Label for="zipcode" className="label">
                                Zipcode* Only Digits (Example: 12345)
                              </Label>
                              <Input
                                type="text"
                                name="zipCode"
                                id="zipcode"
                                className={validClassZip}
                                value={values.zipCode}
                                onChange={handleChangeOption}
                              />
                              {isDisplayError && (
                                <p className="text-danger">
                                  {formErrors.zipCode}
                                </p>
                              )}
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup>
                              <Label for="phone" className="label">
                                Phone (10 Digits)* Ex - 1112223333
                              </Label>
                              <Input
                                type="number"
                                className={validClassContact}
                                name="contactNo"
                                id="phone"
                                value={values.contactNo}
                                onChange={handleChangeOption}
                              />
                              {isDisplayError && (
                                <p className="text-danger">
                                  {formErrors.contactNo}
                                </p>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>



                        <Row>
                          <div className="billing-btn-div">
                            <Button
                              className="btn mt-2 wid"
                              onClick={() => {
                                setToggleUserForm(!toggleUserForm);
                                setEditShippAddress(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="btn btn-dark mt-2 wid"
                              onClick={
                                editShippAddress ? handleUpdate : handleSubmit
                              }
                            >
                              {editShippAddress
                                ? "Update Address"
                                : "Save & Continue"}
                            </Button>{" "}
                          </div>
                        </Row>
                      </Form>
                    )}
                    {!toggleUserForm && allShippingAddress.length > 0 ? (
                      <div>
                        <div>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="Country" className="label">
                                  Address*
                                </Label>

                                <div style={{ position: "relative" }}>
                                  <select
                                    name="chooseDefualtShippAdd"
                                    className="form-control"
                                    onChange={handleChangeAddress}
                                    value={chooseDefualtShippAdd}
                                    data-choices
                                    data-choices-sorting="true"
                                  >
                                    {// <option>Select Address</option>
                                    }
                                    {allShippingAddress.map((c) => {
                                      return (
                                        <React.Fragment key={c._id}>
                                          {c.IsActive && (
                                            <option value={c._id}>
                                              {c.addressLine1}
                                            </option>
                                          )}
                                        </React.Fragment>
                                      );
                                    })}
                                  </select>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <Row>
                          <Col lg={8}>
                            <p>
                              {VFN} {VLN}
                              <br />
                              {VCN}
                              <br />
                              {VC}
                            </p>

                            <p>
                              {vA1}
                              <br />
                              {vA2}
                              <br />
                              {Vcity} - {Vz}
                              <br />
                              {stateName}
                              <br />
                              {countryName} <br />
                            </p>
                            {isContinue ? (
                              <p style={{ color: "#808080" }}>
                                {" "}
                                Click on continue to save address.
                              </p>
                            ) : null}
                          </Col>
                          <Col lg={4} className="d-flex justify-content-end">
                            <button
                              onClick={handleToggleUserForm}
                              cursor="pointer"
                              style={{
                                display: `${toggleUserForm ? "none" : "block"}`,
                                background: "none",
                                border: "none",
                                padding: "0",
                                textDecoration: "underline",
                                color: "inherit",
                              }}
                            >
                              <u>Edit</u>
                            </button>
                          </Col>
                        </Row>{" "}
                        {/* <div style={{ display: "flex" }}>
                      <Button onClick={handleToggleUserForm} type="button">
                        Continue
                      </Button>
                      <Button onClick={handleToggleUserForm} type="button">
                        Add New Address
                      </Button>
                    </div> */}
                        <div className="billing-btn-div">
                          <Button
                            hidden
                            className="btn  btn-dark mt-2 wid"
                            // onClick={handleToggleUserForm}
                            onClick={handleContinue}
                          >
                            Continue
                          </Button>
                          <Button
                            hidden
                            className="btn btn-dark mt-2 wid"
                            // onClick={handleToggleUserForm}
                            onClick={handleAddNewAddress}
                          >
                            Add New Address
                          </Button>{" "}
                        </div>
                      </div>
                    ) : (
                      !toggleUserForm &&
                      isUserID && (
                        <div className="billing-btn-div">
                          <Button
                            className="btn btn-dark mt-2 wid"
                            // onClick={handleToggleUserForm} 
                            onClick={handleAddNewAddress}
                          >
                            Add New Address
                          </Button>
                        </div>
                      )
                    )}
                  </Card>

                  <Card className="p-3">
                    <CardHeader className="card-header-flex">
                      <h6 >Payment <span style={{ color: "red" }}>*</span></h6>
                      {/*!toggleLeftSec.payment && (
                        <FaAngleDown onClick={() => handleToggleLeftSec(2)} />
                      )}
                      {toggleLeftSec.payment && (
                        <FaAngleUp onClick={() => handleToggleLeftSec(4)} />
                      ) */}
                    </CardHeader>
                    {toggleLeftSec.payment && (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <div className="radio-div">
                              <Input
                                type="radio"
                                name="paymentMethod"
                                id=""
                                checked={paymentMethod === "cardPayment"}
                                value="cardPayment"
                                disabled={isSpinner}
                                onChange={handlePayment}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "90%",
                                }}
                              >
                                <p style={{ marginBottom: "auto" }}>
                                  Credit/Debit Cards
                                </p>
                                <p style={{ marginBottom: "auto" }}>
                                  <img src={maestro} />
                                  <img src={amex} alt="" />
                                  <img src={visa} alt="" />
                                  <img src={mastercard} alt="" />
                                  ...
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row hidden={isasub}>
                          <Col lg={12}>
                            <div className="radio-div">
                              <Input
                                disabled={isSpinner}
                                type="radio"
                                name="paymentMethod" 
                                id="paypalPayment"
                                checked={paymentMethod === "paypalPayment"}
                                value="paypalPayment"
                                onChange={handlePayment}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "90%",
                                }}
                              >
                                <p style={{ marginBottom: "auto" }}>PayPal</p>
                                <p style={{ marginBottom: "auto" }}>
                                  <img src={paypal} style={{ width: "50px" }} />
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {/* <Row>
                          <Col lg={12}>
                            <div className="radio-div">
                              <Input
                                type="radio"
                                name="paymentMethod"
                                id=""
                                value="ach"
                                onChange={handlePayment}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "90%",
                                }}
                              >
                                <p style={{ marginBottom: "auto" }}>ACH</p>
                                <p style={{ marginBottom: "auto" }}>
                                  <img
                                    src={offline}
                                    style={{ width: "50px" }}
                                  />
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        {paymentMethod === "ach" && (
                          <Row>
                            <Col lg={6}>
                              <FormGroup>
                                <Label for="firstName" className="label">
                                  Routing Number *
                                </Label>
                                <Input
                                  type="text"
                                  name="routingNumber"
                                  placeholder="Enter routing number"
                                />
                              </FormGroup>
                            </Col>

                            <Col lg={6}>
                              <FormGroup>
                                <Label for="firstName" className="label">
                                  Account Number *
                                </Label>
                                <Input type="text" name="accountNumber" />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}

                        <Row hidden={paymentMethod !== "cardPayment" || selecto === 1}>
                          <Row>
                            <Col lg={12}>
                              <FormGroup>
                                <Label for="firstName" className="label">
                                  Card Number *
                                </Label>
                                <Input
                                  type="text"
                                  name="cardNumber"
                                  className={validClassCardNumber}
                                  onChange={(e) =>
                                    setCardValues({
                                      ...cardValues,
                                      cardNumber: e.target.value,
                                    })
                                  }
                                  value={cardValues.cardNumber}
                                  placeholder="Enter card number"
                                />
                                {isCardError && (
                                  <p className="text-danger">
                                    {formErrors.cardNumber}
                                  </p>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <FormGroup>
                                <Label for="firstName" className="label">
                                  Expiration Date *
                                </Label>
                                <Input
                                  type="text"
                                  name="expiryDate"
                                  className={validClassExpDate}
                                  //onChange={(e) =>
                                  //  setCardValues({
                                  //    ...cardValues,
                                  //    expiryDate: e.target.value,
                                  //  })
                                  //}
                                  onChange={(e) => handleExpChange(e)}
                                  value={expirationdate}
                                  placeholder="MM / YY"
                                />
                                {shower && <p className="text-danger">Invalid expiryDate</p>}
                                {isCardError && (
                                  <p className="text-danger">
                                    {formErrors.expiryDate}
                                  </p>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg={6}>
                              <FormGroup>
                                <Label for="lastName" className="label">
                                  Security Code (CVV) *
                                </Label>
                                <Input
                                  type="text"
                                  name="cvv"
                                  className={validClassCVV}
                                  onChange={(e) =>
                                    setCardValues({
                                      ...cardValues,
                                      cvv: e.target.value,
                                    })
                                  }
                                  value={cardValues.cvv}
                                  placeholder="X X X"
                                />
                                {isCardError && (
                                  <p className="text-danger">
                                    {formErrors.cvv}
                                  </p>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <FormGroup>
                                <Label for="firstName" className="label">
                                  Cardholder Name *
                                </Label>
                                <Input
                                  type="text"
                                  name="cardholderName"
                                  className={validClassCardName}
                                  onChange={(e) =>
                                    setCardValues({
                                      ...cardValues,
                                      cardHolderName: e.target.value,
                                    })
                                  }
                                  value={cardValues.cardHolderName}
                                />
                                {isCardError && (
                                  <p className="text-danger">
                                    {formErrors.cardHolderName}
                                  </p>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <FormGroup style={{ borderBottom: "2px solid black", borderTop: "2px solid black", paddingTop: "10px", paddingBottom: "10px", marginLeft: "5px", marginRight: "0px" }} check>
                              <Label style={{ color: "grey" }} check className="label">
                                Save card in Wallet
                              </Label>
                              <Input
                                disabled
                                type="checkbox"
                                name="cardsaver"
                                checked={"true"}
                              // onChange={handleCheckboxChange}
                              // value={isChecked}
                              />{" "}
                            </FormGroup>
                          </Row>

                          <Row>
                            <div className="billing-btn-div">
                              <Button
                                hidden
                                className="btn mt-2 wid"
                                onClick={() => {
                                  setToggleCreditCardForm(
                                    !toggleCreditCardForm
                                  );
                                  // setEditShippAddress(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="btn btn-dark mt-2 wid"
                                onClick={handleSaveCreditCard}
                              >
                                Save
                              </Button>{" "}


                            </div>
                          </Row>
                        </Row>

                        {allCreditCards &&
                          allCreditCards.length > 0 && (
                            <Row hidden={paymentMethod !== "cardPayment"} style={{ border: "2px solid #dee2e6", padding: "10px", marginTop: "10px", marginLeft: "1px", marginRight: "1px" }}>
                              <Col>
                                <Label className="mt-2">
                                  Credit/Debit Card
                                </Label>
                                <FormGroup>
                                  <div style={{ position: "relative" }}>
                                    <select
                                      name="selectedCreditCard"
                                      className="form-control"
                                      onChange={(e) =>
                                        setSelectedCreditCard(
                                          e.target.value
                                        )
                                      }
                                      value={selectedCreditCard}
                                      data-choices
                                      data-choices-sorting="true"
                                    >
                                      <option>Select Card</option>
                                      {allCreditCards.map((c) => {

                                        if (selecto === 0) {
                                          setSelectedCreditCard(c._id);
                                          setSelecto(1);
                                        }

                                        const cardNumberString =
                                          c.cardNumber
                                            ? c.cardNumber.toString()
                                            : "";
                                        const maskedCardNumber =
                                          cardNumberString
                                            ? cardNumberString.replace(
                                              /.(?=.{4})/g,
                                              "*"
                                            )
                                            : "";
                                        return (
                                          <React.Fragment key={c._id}>
                                            {c.isActive && (
                                              <option value={c._id}>
                                                {c.cardHolderName}
                                                {"  "}
                                                {maskedCardNumber}

                                              </option>
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          )}


                        { (
                          <Row>
                            <Col lg={12}>
                              <h6 className="mt-4 bg-light p-2">
                                Billing address <span style={{ color: "red" }}>*</span>
                              </h6>
                              <FormGroup style={{ borderBottom: "2px solid black", borderTop: "2px solid black", paddingTop: "10px", paddingBottom: "10px" }} check>
                                <Label check className="label">
                                  Same as Account / Default Address
                                </Label>
                                <Input
                                  type="checkbox"
                                  name="Samedeliveryaddress"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                  value={isChecked}
                                />{" "}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        <Row hidden={!isChecked}>
                          <Col>
                            <FormGroup>
                              <Label for="Country" className="label">
                                Address*
                              </Label>

                              <div style={{ position: "relative" }}>
                                <select
                                  name="chooseDefualtShippAdd"
                                  className="form-control"
                                  onChange={handleChangeAddress}
                                  value={chooseDefualtShippAdd}
                                  data-choices
                                  data-choices-sorting="true"
                                >
                                  {allShippingAddress.map((c) => {
                                    return (
                                      <React.Fragment key={c._id}>
                                        {c.IsActive && (
                                          <option value={c._id}>
                                            {c.addressLine1}
                                          </option>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </select>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row hidden={!isChecked}>
                          <Col lg={8}>
                            <p>
                              {VFN} {VLN}
                              <br />
                              {VCN}
                              <br />
                              {VC}
                            </p>

                            <p>
                              {vA1}
                              <br />
                              {vA2}
                              <br />
                              {Vcity} - {Vz}
                              <br />
                              {stateName}
                              <br />
                              {countryName} <br />
                            </p>
                            {isContinue ? (
                              <p style={{ color: "#808080" }}>
                                {" "}
                                Click on continue to save address.
                              </p>
                            ) : null}
                          </Col>
                          <Col lg={4} className="d-flex justify-content-end">
                            <button
                              onClick={handleToggleUserForm}
                              cursor="pointer"
                              style={{
                                display: `${toggleUserForm ? "none" : "block"}`,
                                background: "none",
                                border: "none",
                                padding: "0",
                                textDecoration: "underline",
                                color: "inherit",
                              }}
                            >

                            </button>
                          </Col>
                        </Row>{" "}
                        {!isChecked && (
                          <Row>
                            <Col lg={12}>
                              {/*  */}
                              <Form>
                                <h6 className="mt-2">Billing Details</h6>
                                <Row>
                                  <Col lg={6}>
                                    <FormGroup>
                                      <Label for="firstName" className="label">
                                        First Name*
                                      </Label>
                                      <Input
                                        type="text"
                                        name="firstNameBA"
                                        id="firstNameBA"
                                        className={validClassFNBA}
                                        value={valuesBA.firstNameBA}
                                        onChange={handleChangeOptionBA}
                                      />
                                      {isDisplayErrorBA && (
                                        <p className="text-danger">
                                          {formErrorsBA.firstNameBA}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg={6}>
                                    <FormGroup>
                                      <Label for="lastName" className="label">
                                        Last Name
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        name="lastNameBA"
                                        id="lastName"
                                        value={valuesBA.lastNameBA}
                                        onChange={handleChangeOptionBA}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>

                                  <Col>
                                    <FormGroup>
                                      <Label for="phone" className="label">
                                        Company Name
                                      </Label>
                                      <Input
                                        type="text"
                                        name="companyNameBA"
                                        id="companyNameBA"
                                        value={valuesBA.companyNameBA}
                                        onChange={handleChangeOptionBA}
                                      />
                                    </FormGroup>
                                  </Col>

                                </Row>

                                <FormGroup>
                                  <Label for="address" className="label">
                                    Address*
                                  </Label>
                                  <Input
                                    type="text"
                                    name="addressLine1BA"
                                    className={validClassAddBA}
                                    id="addressLine1BA"
                                    value={valuesBA.addressLine1BA}
                                    onChange={handleChangeOptionBA}
                                  />
                                  {isDisplayErrorBA && (
                                    <p className="text-danger">
                                      {formErrorsBA.addressLine1BA}
                                    </p>
                                  )}
                                </FormGroup>

                                <FormGroup>
                                  <Label for="address" className="label">
                                    Address - line 2
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="addressLine2BA"
                                    id="addressLine2BA"
                                    value={valuesBA.addressLine2BA}
                                    onChange={handleChangeOptionBA}
                                  />
                                </FormGroup>

                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="State" className="label">
                                        city
                                      </Label>
                                      <Input
                                        type="text"
                                        className={validClassCityNameBA}
                                        name="cityBA"
                                        id="cityBA"
                                        value={valuesBA.cityBA}
                                        onChange={handleChangeOptionBA}
                                      />
                                      {isDisplayErrorBA && (
                                        <p className="text-danger">
                                          {formErrorsBA.cityBA}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </Col>

                                  <Col hidden>
                                    <FormGroup>
                                      <Label for="Country" className="label">
                                        Country*
                                      </Label>

                                      <select
                                        name="countryIdBA"
                                        className={validClassCountryNameBA}
                                        onChange={handleChangeOptionBA}
                                        value={valuesBA.countryIdBA}
                                        data-choices
                                        data-choices-sorting="true"
                                      >
                                        <option>Select Country</option>
                                        {countries.map((c) => {
                                          return (
                                            <React.Fragment key={c._id}>
                                              {c.isActive && (
                                                <option value={c._id}>
                                                  {c.CountryName}
                                                </option>
                                              )}
                                            </React.Fragment>
                                          );
                                        })}
                                      </select>
                                      {isDisplayErrorBA && (
                                        <p className="text-danger">
                                          {formErrorsBA.countryIdBA}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup>
                                      <Label for="State" className="label">
                                        State*
                                      </Label>

                                      <select
                                        name="stateIdBA"
                                        className={validClassStateNameBA}
                                        onChange={handleChangeOptionBA}
                                        value={valuesBA.stateIdBA}
                                        data-choices
                                        data-choices-sorting="true"
                                      >
                                        <option>Select State </option>
                                        {states.map((s) => {
                                          return (
                                            <React.Fragment key={s._id}>
                                              {s.isActive &&
                                                countryIdBA === s.CountryID && (
                                                  <option value={s._id}>
                                                    {s.StateName}
                                                  </option>
                                                )}
                                            </React.Fragment>
                                          );
                                        })}
                                      </select>
                                      {isDisplayErrorBA && (
                                        <p className="text-danger">
                                          {formErrorsBA.stateIdBA}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>

                                  <Col>
                                    <FormGroup>
                                      <Label for="zipcode" className="label">
                                        Zipcode* Only Digits (Example: 12345)
                                      </Label>
                                      <Input
                                        type="text"
                                        name="zipCodeBA"
                                        id="zipCodeBA"
                                        className={validClassZipBA}
                                        value={valuesBA.zipCodeBA}
                                        onChange={handleChangeOptionBA}
                                      />
                                      {isDisplayErrorBA && (
                                        <p className="text-danger">
                                          {formErrorsBA.zipCodeBA}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup>
                                      <Label for="phone" className="label">
                                        Phone (10 Digits)* Ex - 1112223333
                                      </Label>
                                      <Input
                                        type="tel"
                                        className={validClassContactBA}
                                        maxLength="10"
                                        name="contactNoBA"
                                        id="phone"
                                        value={valuesBA.contactNoBA}
                                        onChange={handleChangeOptionBA}
                                      />
                                      {isDisplayErrorBA && (
                                        <p className="text-danger">
                                          {formErrorsBA.contactNoBA}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <div className="billing-btn-div">
                                  <Button
                                    hidden
                                    className="btn btn-dark mt-2 wid"
                                    onClick={() => handleToggleLeftSec(4)}
                                  // onClick={() => navigate("/thankyou")}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    disabled={billa === 1}
                                    className="btn btn-dark mt-2 wid"
                                    // onClick={() => navigate("/thankyou")}]
                                    onClick={handleCreateBillingAddress}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col lg={12}>
                            <p className="mtop-20">Review & place order</p>
                            <p>
                              Review the order details above, and place your
                              order when you’re ready.
                            </p>
                            <FormGroup check>
                              <Label check className="label">
                                I agree to the
                                <Link to="/store-policy">
                                  <u style={{ color: "black" }}>
                                    {" "}
                                    Terms & Conditions
                                  </u>
                                </Link>
                                ,{" "}
                                <Link to="/store-policy">
                                  <u style={{ color: "black" }}>
                                    {" "}
                                    Privacy Policy
                                  </u>
                                </Link>
                                and
                                <Link to="/shipping">
                                  <u style={{ color: "black" }}>
                                    {" "}
                                    Return Policy
                                  </u>{" "}
                                </Link>
                              </Label>
                              <Input
                                type="checkbox"
                                name="agree"
                                value={agree}
                                checked={agree}
                                onChange={handleAgreeChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {isUserID && (
                            <Button
                              className="btn btn-dark mt-2"
                              // onClick={() => navigate("/thankyou")}
                              onClick={handleCheckout}
                              disabled={
                                !agree ||
                                parseFloat(totalAmount) +
                                parseFloat(shippingCharge) <
                                1 ||
                                isSpinner
                              }
                              style={{ position: "relative", height: "30px" }} // Set position relative for the button wrapper
                            >
                              {isSpinner ? (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)", // Center the spinner vertically and horizontally
                                  }}
                                >
                                  <TailSpin
                                    visible={true}
                                    height="20"
                                    width="30"
                                    color="#fff"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                </span>
                              ) : (
                                <span>Place Order & Pay</span>
                              )}
                            </Button>
                          )}
                        </Row>
                        {checkoutError ? (
                          <p className="text-danger">{checkoutMessage}</p>
                        ) : null}
                      </Form>
                    )}
                  </Card>
                </Col>
                <Col lg={5}>
                  <div className="order-summery-card">
                    <div className="order-card-header">
                      <h5>Order Summary</h5>

                      <Link to="/shopping-cart" className="btn_text m-1">
                        <span>
                          <CiShoppingCart /> Edit Cart
                        </span>
                      </Link>
                    </div>
                    <hr></hr>

                    <table>
                      <tbody>
                        {/* {fakeData.map((item) => ( */}
                        {cartDetails.map((item) => (
                          <tr key={item._id}>
                            <td style={{ width: "30%" }}>
                              <img
                                className="order-product-image"
                                // src={item.productImage}
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${item.productImage}`}
                                alt={item.productName}
                              />{" "}
                            </td>
                            <td
                              className="order-summery-name-price"
                              style={{ width: "40%" }}
                            >
                              <h6>{item.productName} </h6>{" "}
                              {item.subsId && (
                                <p>
                                  <b>
                                    <i>Subscribed:</i>
                                  </b>{" "}
                                  <i> {item.subsName} </i>
                                </p>
                              )}
                              {prodVariantsData.find(
                                (variant) =>
                                  variant._id === item.productVariantsId
                              ) && (
                                  <p>
                                    {prodVariantsData
                                      .find(
                                        (variant) =>
                                          variant._id === item.productVariantsId
                                      )
                                      .parameters.map((param, index) => (
                                        <React.Fragment key={index}>
                                          <b>{param.parameterName}</b>:{" "}
                                          {param.parameterValue}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                  </p>
                                )}
                            </td>
                            <td style={{ width: "20%" }}>
                              <p>Qty : {item.quantity}</p>
                            </td>
                            <td style={{ width: "20%" }}>
                              ${item.totalAmount.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <hr />
              <div className="d-flex flex-column">
                      <Link hidden={isasub} to="#" className="btn_text m-1">
                        <span onClick={handlePromoBtn}>
                          <CiShoppingTag /> Enter Promo Code
                        </span>
                      </Link>
                      <div
                        style={{
                          display: `${togglePromoBtn.promo ? "flex" : "none"}`,
                          justifyContent: "space-between",
                        }}
                      >
                        <input
                        hidden={isasub}
                          type="text"
                          placeholder="Promo code"
                          name="promoCode"
                          value={promoCode}
                          className="m-2"
                          onChange={handleChangePromo}
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                        />

                        {isPromo ? (
                          <Button
                          hidden={isasub}
                            style={{
                              backgroundColor: "black",
                              border: "none",
                              color: "white",
                              width: "40%",
                              height: "40px",
                            }}
                            onClick={handleRemovePromo}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                          hidden={isasub}
                            style={{
                              backgroundColor: "black",
                              border: "none",
                              color: "white",
                              width: "40%",
                              height: "40px",
                            }}
                            onClick={handleApplyPromo}
                          >
                            Apply
                          </Button>
                        )}
                      </div>
                      {notValidPromo && (
                        <p style={{ color: "red" }}>Promo code is not valid.</p>
                      )}

                      {promoList.length > 0 && (
                        <React.Fragment>
                          {/* <PromoCarousel promo={promoList} /> */}
                          <Carousel responsive={responsive}>
                            {promoList.length > 0 &&
                              promoList.map((it) => {
                                return (
                                  <div>
                                    {it.IsShow && (
                                      <Card
                                        className="findCard btn btn-outline-light"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setPromocode(it.code);
                                          setPromo(false);
                                          setNotValidPromo(false);
                                          setTogglePromoBtn({
                                            promo: true,
                                            note: false,
                                            tip: false,
                                          });
                                        }}
                                      >
                                        <h6>{it.code}</h6>
                                        {it.type == "Credit" && (
                                          <p>GET {it.points} points</p>
                                        )}
                                        {it.type == "Debit" && (
                                          <p>OFF {it.points} points</p>
                                        )}
                                      </Card>
                                    )}
                                  </div>
                                );
                              })}
                          </Carousel>
                        </React.Fragment>
                      )}

                      {/* <Link to="#" className="btn_text m-1 mt-4">
                        <span onClick={handleTipBtn}>
                          <CiGift /> Add a Tip
                        </span>
                      </Link>
                      <div
                        style={{
                          display: `${togglePromoBtn.tip ? "flex" : "none"}`,
                          justifyContent: "space-between",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Add a tip"
                          name="tipPoint"
                          value={tipPoint}
                          onChange={(e) => {
                            setTipPoint(e.target.value);
                            setIsTip(false);
                          }}
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                        />
                        {isTip ? (
                          <Button
                            style={{
                              backgroundColor: "#c7a17a",
                              border: "none",
                            }}
                            onClick={() => {
                              setIsTip(false);
                              setAddTip(0);
                              setTipPoint(0);
                            }}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: "black",
                              border: "none",
                              color: "white",
                              width: "40%",
                              height: "40px",
                            }}
                            onClick={handleApplyTip}
                          >
                            Apply
                          </Button>
                        )}
                      </div> */}

                      {/* {tipList.length > 0 && (
                        <React.Fragment>
                          <Carousel responsive={responsive}>
                            {tipList.length > 0 &&
                              tipList.map((it) => {
                                return (
                                  <div>
                                      <Card
                                        className="findCard btn btn-outline-light"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setTipPoint(it.tipName);
                                          setTogglePromoBtn({
                                            promo: false,
                                            note: false,
                                            tip: true,
                                          });
                                        }}
                                      >
                                        <h6>{it.tipName}</h6>
                                      </Card>
                                  </div>
                                );
                              })}
                          </Carousel>
                        </React.Fragment>
                      )} */}

                      {/* <Link className="btn_text m-1 mt-4">
                        <span onClick={handleNoteBtn}>
                          <MdOutlineNoteAlt /> Add a Note
                        </span>
                      </Link>
                      <div
                        style={{
                          display: `${togglePromoBtn.note ? "block" : "none"}`,
                        }}
                      >
                        <textarea
                          className="m-2"
                          name="orderComment"
                          value={orderComment}
                          onChange={handleOrderComment}
                          // name=""
                          id=""
                          cols="50"
                          rows="5"
                          placeholder="Instructions? Special requests? Add them here."
                        ></textarea>
                        <br />
                      </div> */}
                    </div>
                    {/* <hr /> */}

                    {/* {points < 50 ? (
                      <div className="d-flex flex-column">
                        <p>
                          <MdOutlineStars /> You have {points} Loyalty Points
                        </p>
                        <p>
                          {50 - points} more Loyalty Points needed to get $5 off
                        </p>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box sx={{ width: "100%", mr: 1 }}>
                            <LinearProgress
                              variant="determinate"
                              value={points}
                              sx={{ color: "grey" }}
                            />
                          </Box>
                          <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">
                              {points}/50
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    ) : (
                      <div className="d-flex flex-column">
                        {isLoyaltypoints && loyaltypoints > 0 ? (
                          <p>
                            <MdOutlineStars /> You have {points - loyaltypoints}{" "}
                            Loyalty Points
                          </p>
                        ) : (
                          <p>
                            <MdOutlineStars /> You have {points} Loyalty Points
                          </p>
                        )}
                        <p>Redeem Loyalty Points for a rewards coupon:</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="number"
                            placeholder="e.g., 50"
                            className="m-2"
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "50%",
                            }}
                            // disabled={isPromo}
                            name={loyaltypoints}
                            value={loyaltypoints}
                            onChange={(e) => {
                              setLoyaltypoints(e.target.value);
                              // setIsLoyaltypoints(false);
                            }}
                          />
                          {loyaltypoints && isLoyaltypoints ? (
                            <Button
                              style={{
                                backgroundColor: "black",
                                border: "none",
                                color: "white",
                                width: "40%",
                                height: "40px",
                              }}
                              // disabled={loyaltypoints<50}
                              onClick={handelRemovePoint}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              style={{
                                backgroundColor: "black",
                                border: "none",
                                color: "white",
                                width: "40%",
                                height: "40px",
                              }}
                              disabled={
                                loyaltypoints < 50 ||
                                loyaltypoints > points ||
                                loyaltypoints % pointsPerDollar !== 0 ||
                                loyaltypoints / pointsPerDollar >
                                  subTotal - productDiscount - promoDiscount
                              }
                              onClick={handelApplyPoint}
                            >
                              Apply
                            </Button>
                          )}
                        </div>
                        {loyaltypoints % pointsPerDollar !== 0 &&
                          loyaltypoints <= points && (
                            <p style={{ color: "red" }}>
                              Points must be multiples of {pointsPerDollar}.
                            </p>
                          )}
                        {loyaltypoints < 50 && (
                          <p style={{ color: "red" }}>
                            Enter a number that's 50 or higher.
                          </p>
                        )}
                        {loyaltypoints > points && (
                          <p style={{ color: "red" }}>
                            Not enough points to use!
                          </p>
                        )}
                        {loyaltypoints % pointsPerDollar == 0 &&
                          loyaltypoints / pointsPerDollar >
                            subTotal - productDiscount - promoDiscount &&
                          loyaltypoints >= 50 &&
                          loyaltypoints <= points && (
                            <p style={{ color: "red" }}>
                              Points amount exceeded the subtotal!
                            </p>
                          )}
                        <p>50 Loyalty Points = $5 off</p>
                      </div>
                    )} */}

                    {isPromo && promoDetails.type == "Credit" && (
                      <p>
                        Earn <b>{promoDetails.points}</b> Loyalty Points using
                        promocode when you place this order.
                      </p>
                    )}

                    {productPoints > 0 && (
                      <p>
                        Earn <b>{productPoints}</b> Loyalty Points when you
                        order these products.
                      </p>
                    )}

                    <hr />

                    <div className="d-flex justify-content-between">
                      <h6>Subtotal</h6> <p>${subTotal.toFixed(2)}</p>
                    </div>

                    {promoCode && isPromo && (
                      <div className="d-flex justify-content-between">
                        <h6>Promo Code</h6> <p>{promoCode}</p>
                      </div>
                    )}

                    {promoDetails &&
                      promoDetails.type == "Debit" &&
                      isPromo && (
                        <div className="d-flex justify-content-between">
                          <h6>Promo Code Discount</h6>{" "}
                          <p>-{promoDiscount.toFixed(2)}</p>
                        </div>
                      )}

                    {loyaltypoints > 0 && isLoyaltypoints && (
                      <div className="d-flex justify-content-between">
                        <h6>Discount</h6>{" "}
                        <p>-{(loyaltypoints / pointsPerDollar).toFixed(2)}</p>
                      </div>
                    )}

                    {productDiscount > 0 && (
                      <div className="d-flex justify-content-between">
                        <h6> Product Discount</h6> <p>-{productDiscount}</p>
                      </div>
                    )}

                    {isTip && (
                      <div className="d-flex justify-content-between">
                        <h6> Tip</h6> <p> {tipPoint} </p>
                      </div>
                    )}

                    <hr />

                    <div className="d-flex justify-content-between">
                      <h6>Delivery</h6> <p>${shippingCharge}.00</p>
                    </div>

                    {/* <div className="d-flex justify-content-between">
                      <h6>Sales Tax</h6> <p>$ 00.00</p>
                    </div> */}

                    <hr />

                    <div className="d-flex justify-content-between">
                      <h6>Total</h6>{" "}
                      <p>${(totalAmount + shippingCharge).toFixed(2)}</p>
                      {parseFloat(totalAmount) + parseFloat(shippingCharge) <
                        1 && (
                          <p style={{ color: "red" }}>
                            Total amount should be more than $1.00.
                          </p>
                        )}
                    </div>
                  </div>
                  <p className="secure-checkout-p">
                    <IoLockClosedSharp /> Secure Checkout
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer />

          <Modal centered show={isPaypal} onHide={() => setPaypal(false)}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Payment Method</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              {cmsPaypalContent && cmsPaypalContent.length > 0 ? (
                cmsPaypalContent.map((c) => {
                  return (
                    <React.Fragment key={c._id}>
                      {c.IsActive &&
                        c.ContentFor ===
                        "Paypal Warning For Subscription Products on Checkout" && (
                          <>
                            <span>
                              <p
                                className="small_title wow fadeInUp"
                                data-wow-delay=".1s"
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                              ></p>
                            </span>
                          </>
                        )}
                    </React.Fragment>
                  );
                })
              ) : (
                <div className="text-center">
                  You will not able to update subscription after using paypal
                  payment method.
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-dark m-1"
                  onClick={() => setPaypal(false)}
                >
                  Ok
                </button>
                <button
                  className="btn btn-lightm-1"
                  onClick={() => setPaypal(false)}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal centered show={paypal2} onHide={() => setPaypal2(false)}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Payment Method</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>

            <div className="text-center">
                A Paypal-payment page will open soon.
              </div>

              <div className="text-center">
                Log-in to your paypal account or click on "return to Nick's Warehouse page."
              </div>

            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-dark m-1"
                  onClick={() => setPaypal2(false)}
                >
                  Ok
                </button>
                <button
                  className="btn btn-lightm-1"
                  onClick={() => setPaypal2(false)}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};

export default Checkout;
